import React, { Component, useState } from 'react'
import { styled } from '@mui/material/styles'
import { lighten, darken } from '@mui/system'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import Badge from '@mui/material/Badge'
import { PickersDay as Day } from '@mui/lab'
import { InView } from 'react-intersection-observer'
import Fuse from 'fuse.js'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import moment from 'moment'
import { getTimeAtCurrentDay, EnumerateDaysBetweenDates, getRange, debounce } from 'utils/helpers'
import { validate, v4 as uuidv4 } from 'uuid'
import { EventCard, StickyDiaryCalendar } from '@components'
import SymptomScoresChart from '@components/SymptomScoresChart'
import DiaryEntityChiplist from 'organisms/Diary/DiaryEntityChiplist'
import { ENTITY_THEME } from 'constants'
import _debounce from 'lodash.debounce'
import Excellent from 'images/emotions/surprised.png'
import Great from 'images/emotions/grin.png'
import Decent from 'images/emotions/smile.png'
import Okay from 'images/emotions/moderate.png'
import Mild from 'images/emotions/sad.png'
import Bad from 'images/emotions/worried.png'
import Terrible from 'images/emotions/terrible.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint } from '@fortawesome/free-solid-svg-icons'

import groupBy from 'lodash.groupby'
import { navigate } from 'gatsby'

const PREFIX = 'DiaryDay'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	loadingContentWrapper: `${PREFIX}-loadingContentWrapper`,
	container: `${PREFIX}-container`,
	contentGridMain: `${PREFIX}-contentGridMain`,
	contentGridSecondary: `${PREFIX}-contentGridSecondary`,
	separate: `${PREFIX}-separate`,
	alertMargin: `${PREFIX}-alertMargin`,
	rangeInternalPreview: `${PREFIX}-rangeInternalPreview`,
	day: `${PREFIX}-day`,
	inRange: `${PREFIX}-inRange`,
	inRangeFocus: `${PREFIX}-inRangeFocus`,
	inRangeOpaque: `${PREFIX}-inRangeOpaque`,
	inRangeStart: `${PREFIX}-inRangeStart`,
	inRangeEnd: `${PREFIX}-inRangeEnd`,
	dayWithDotContainer: `${PREFIX}-dayWithDotContainer`,
	dayWithUnlabled: `${PREFIX}-dayWithUnlabled`,
	dayWithDot: `${PREFIX}-dayWithDot`,
	dotsPadding: `${PREFIX}-dotsPadding`,
	diaryDayHeader: `${PREFIX}-diaryDayHeader`,
	compactSearch: `${PREFIX}-compactSearch`,
	menstruationBackdrop: `${PREFIX}-menstruationBackdrop`,
}

const Root = styled('div')(({ theme }) => {
	return {
		[`&.${classes.container}`]: {
			padding: '48px 36px 48px',
		},
		[`& .${classes.paper}`]: {
			margin: 'auto',
			overflow: 'hidden',
		},
		[`& .${classes.searchBar}`]: {
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		},
		[`& .${classes.searchInput}`]: {
			fontSize: theme.typography.fontSize,
		},
		[`& .${classes.block}`]: {
			display: 'block',
		},
		[`& .${classes.loadingContentWrapper}`]: {
			margin: '30px 16px',
		},
		[`& .${classes.contentGridMain}`]: {
			[theme.breakpoints.down(`sm`)]: {
				order: 2,
			},
		},
		[`& .${classes.contentGridSecondary}`]: {
			[theme.breakpoints.down(`sm`)]: {
				order: 1,
			},
		},
		[`& .${classes.separate}`]: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
		[`& .${classes.alertMargin}`]: {
			marginBottom: theme.spacing(2),
		},
		[`& .${classes.rangeInternalPreview}`]: {
			border: '2px solid transparent',
		},
		[`& .${classes.day}`]: {
			transform: 'scale(1.1)',
			backgroundColor: 'transparent',
		},
		[`& .${classes.inRange}`]: {
			backgroundColor: `rgba(${parseInt(theme.palette.primary[500].substring(1, 3), 16)}, ${parseInt(
				theme.palette.primary[500].substring(3, 5),
				16
			)}, ${parseInt(theme.palette.primary[500].substring(5, 7), 16)}, 0.6)`,
			borderRadius: 0,
			'&:first-of-type': {
				borderTopLeftRadius: '50%',
				borderBottomLeftRadius: '50%',
			},
			'&:last-of-type': {
				borderTopRightRadius: '50%',
				borderBottomRightRadius: '50%',
			},
		},
		[`& .${classes.inRangeFocus}`]: {
			backgroundColor: `${theme.palette.primary[500]}!important`,
		},
		[`& .${classes.inRangeOpaque}`]: {
			backgroundColor: 'transparent',
			borderRadius: '50%',
		},
		[`& .${classes.inRangeStart}`]: {
			marginLeft: 1,
			paddingLeft: 0,
			borderTopLeftRadius: '50%',
			borderBottomLeftRadius: '50%',
		},
		[`& .${classes.inRangeEnd}`]: {
			marginRight: 1,
			paddingRight: 0,
			borderTopRightRadius: '50%',
			borderBottomRightRadius: '50%',
		},
		[`& .${classes.dayWithDotContainer}`]: {
			position: 'relative',
			maxWidth: 38,
		},
		[`& .${classes.dayWithUnlabled}`]: {
			position: 'absolute',
			zIndex: 1,
			height: 10,
			width: 10,
			border: '2px solid',
			borderRadius: 10,
			margin: '1px 1px 0px 1px',
			borderColor: 'red',
			backgroundColor: 'red',
			top: '5%',
			right: '5%',
		},
		[`& .${classes.dayWithDot}`]: {
			height: 2,
			width: 2,
			border: '2px solid',
			borderRadius: 4,
			margin: '1px 1px 0px 1px',
			top: '80%',
		},
		[`& .${classes.dotsPadding}`]: {
			position: 'absolute',
			marginLeft: 1,
			top: '80%',
		},
		[`& .${classes.diaryDayHeader}`]: {
			padding: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			background: `linear-gradient(90deg, 
        ${
			theme.palette.mode === 'dark'
				? lighten(theme.palette.background.paper, 0.7)
				: darken(theme.palette.background.paper, 0.2)
		} 3%,
        ${theme.palette.background.paper} 90%)`,
		},
		[`& .${classes.compactSearch}`]: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		[`&.${classes.menstruationBackdrop}`]: {
			marginRight: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: 30,
			height: 30,
			borderRadius: 30 / 2,
			backgroundColor: '#FFB6D9',
		},
	}
})

const mapScoreToEmoticons = (num) => {
	if (num === 0) {
		return Terrible
	} else if (num > 0 && num <= 17) {
		return Bad
	} else if (num > 17 && num <= 33) {
		return Mild
	} else if (num > 33 && num <= 50) {
		return Okay
	} else if (num > 50 && num <= 67) {
		return Decent
	} else if (num > 67 && num <= 83) {
		return Great
	} else if (num > 83 && num <= 100) {
		return Excellent
	}
}

const mapScoreToText = (num) => {
	if (num === 0) {
		return 'Terrible'
	} else if (num > 0 && num <= 17) {
		return 'Bad'
	} else if (num > 17 && num <= 33) {
		return 'Mild'
	} else if (num > 33 && num <= 50) {
		return 'Okay'
	} else if (num > 50 && num <= 67) {
		return 'Decent'
	} else if (num > 67 && num <= 83) {
		return 'Great'
	} else if (num > 83 && num <= 100) {
		return 'Excellent'
	}
}

const RenderMenstruatingIcon = (props) => {
	const {} = props
	return (
		<Tooltip title={`Menstruating`} arrow>
			<div className={classes.menstruationBackdrop}>
				<FontAwesomeIcon size={18} solid icon={faTint} color={'#F63A67'} />
			</div>
		</Tooltip>
	)
}

const activeFetchTypes = new Set()
const activeLoadMedias = new Set()
let staleLoader = 0

const renderDayInPicker = (day, dateProps, range, daysEvents, interestedDiaryEntities, classes) => {
	const daysWithDot = Object.keys(daysEvents)
	if (daysWithDot.includes(day.format('YYYY-MM-DD')) && dateProps.inCurrentMonth) {
		const entityTypes = Array.from(
			daysEvents[day.format('YYYY-MM-DD')].reduce((acc, entity) => {
				if (interestedDiaryEntities.includes(entity._app.type)) {
					acc.add(entity._app.type)
				}
				return acc
			}, new Set())
		)
		const hasUnlabeled = daysEvents[day.format('YYYY-MM-DD')].reduce((acc, entity) => {
			if (entity.uuid && entity._app?.unlabled) {
				acc = true
			}
			return acc
		}, false)
		const isTailDay = dateProps.isEndOfHighlighting || dateProps.isStartOfHighlighting
		const isActiveDay = dateProps.isHighlighting
		const isSingleActiveDay = range.includes(moment(dateProps.key).format('YYYY-MM-DD'))

		return (
			<div
				key={dateProps.key}
				className={
					classNames()
					// classes.dayWithDotContainer,
					// isActiveDay ? classes.inRange : '',
					// dateProps.isStartOfHighlighting ? classes.inRangeStart : '',
					// dateProps.isEndOfHighlighting ? classes.inRangeEnd : ''
				}
			>
				{true ? <div className={classes.dayWithUnlabled}></div> : <></>}
				{/* <div className={classes.rangeInternalPreview}> */}
				<Day
					{...dateProps}
					className={classNames(
						classes.day
						// isActiveDay && !isTailDay ? classes.inRangeOpaque : '',
						// isActiveDay ? classes.inRangeFocus : '',
						// isSingleActiveDay ? classes.inRangeFocus : ''
					)}
				/>
				{/* </div> */}
				<Grid container justifyContent="center" className={classes.dotsPadding}>
					{entityTypes.map((type) => {
						return (
							<div
								key={type}
								className={classes.dayWithDot}
								style={{ borderColor: ENTITY_THEME[type.toUpperCase()] }}
							/>
						)
					})}
				</Grid>
			</div>
		)
	}

	return <Day {...dateProps} className={classes.day} />
}

class DayContent extends Component {
	constructor(props) {
		super(props)
	}
	static getDerivedStateFromProps(props, state) {
		if (moment(props.summary.attentionDate).isBefore(state.historyDateAnchor)) {
			state.historyDateAnchor = moment(props.summary.attentionDate).subtract(7, 'days').format('YYYY-MM-DD')
		}
		return {
			...state,
			currentDay: moment(props.summary.attentionDate).format('YYYY-MM-DD'),
			currentDate: props.summary.attentionDate,
			groupedByDate: props.summary.daysEvents,
		}
	}

	state = {
		searchVal: '',
		currentDate: this.props.summary.attentionDate ? moment(this.props.summary.attentionDate) : moment(),
		historyDateAnchor: this.props.summary.attentionDate
			? moment(this.props.summary.attentionDate).subtract(7, 'days').format('YYYY-MM-DD')
			: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		currentDay: (this.props.summary.attentionDate ? moment(this.props.summary.attentionDate) : moment()).format(
			'YYYY-MM-DD'
		),
		groupedByDate: [],
		entitiesVisible: {},
		contextMenuAnchor: null,
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		const day = this.state.currentDay
		if (day && nextState.currentDay !== day) {
			const todaysEvents =
				nextState.groupedByDate && nextState.groupedByDate[nextState.currentDay]
					? nextState.groupedByDate[nextState.currentDay]
					: []
			if (!todaysEvents.length) {
				this.hydrateData(nextState.currentDay)
			}
		}
		return (
			nextProps.summary.daysEvent !== this.state.groupedByDate ||
			this.props.summary.attentionDate !== nextProps.summary.attentionDate ||
			nextProps.summary.daysEvents[day].length !== this.state.groupedByDate[day].length ||
			!nextProps.summary.daysEvents ||
			!this.state.groupedByDate[day] ||
			!nextProps.summary.daysEvents[day]
		)
	}

	componentDidMount = async () => {
		activeFetchTypes.clear()
		activeLoadMedias.clear()
		await this.hydrateData()
		const days = Object.keys(this.props.summary.daysEvents)
		this.props.setAttentionDate(days.length ? days[0] : moment().format('YYYY-MM-DD'))
	}

	hydrateData = (day = this.state.currentDay) => {
		const dayEvents = this.props.summary.daysEvents[moment(day).format('YYYY-MM-DD')] || []
		if (dayEvents.length === 0 && !this.props.summary.loading) {
			const fromDate = new Date(moment(day))
			const toDate = new Date(moment(day))
			const from = new Date(fromDate.setDate(fromDate.getDate() - 1)).toISOString()
			const to = new Date(toDate.setDate(toDate.getDate() + 1)).toISOString()
			this.props.fetchSummary({
				from,
				to,
			})
			this.props.fetchSymptomScores({
				from,
				to,
			})
			this.props.fetchMenstruations({
				from,
				to,
			})
		}
	}

	// onPressPrevious = () => {
	// 	this.setState((prevState) => {
	// 		this.props.summary.attentionDate = prevState.currentDate.subtract(1, 'days')
	// 		return {
	// 			currentDate: prevState.currentDate.subtract(1, 'days'),
	// 			editingSymptomScore: false,
	// 		}
	// 	})
	// 	activeFetchTypes.clear()
	// 	activeLoadMedias.clear()
	// }

	// onPressNext = () => {
	// 	this.setState((prevState) => {
	// 		this.props.summary.attentionDate = prevState.currentDate.add(1, 'days')
	// 		return {
	// 			currentDate: prevState.currentDate.add(1, 'days'),
	// 			editingSymptomScore: false,
	// 		}
	// 	})
	// 	activeFetchTypes.clear()
	// 	activeLoadMedias.clear()
	// }

	// onPressToday = () => {
	// 	this.setState((prevState) => {
	// 		this.props.summary.attentionDate = moment()
	// 		return {
	// 			currentDate: moment(),
	// 			editingSymptomScore: false,
	// 		}
	// 	})
	// 	activeFetchTypes.clear()
	// 	activeLoadMedias.clear()
	// }

	onPressDate = (date) => {
		this.props.summary.attentionDate = date
		this.setState({ currentDay: date.format('YYYY-MM-DD'), currentDate: date, editingSymptomScore: false })
		activeFetchTypes.clear()
		activeLoadMedias.clear()
	}

	onSetDateRange = (dateA, dateB) => {
		this.props.summary.attentionDate = dateB
		this.setState({
			historyDateAnchor: dateA.format('YYYY-MM-DD'),
			currentDay: dateB.format('YYYY-MM-DD'),
			currentDate: dateB,
			editingSymptomScore: false,
		})
	}

	onRefresh = async () => {
		const fromDate = new Date(moment(this.state.historyDateAnchor))
		const toDate = new Date(moment(this.state.currentDay))
		const from = new Date(fromDate.setDate(fromDate.getDate() - 1)).toISOString()
		const to = new Date(toDate.setDate(toDate.getDate() + 1)).toISOString()
		this.props.fetchSummary({
			from,
			to,
		})
		this.props.fetchSymptomScores({
			from,
			to,
		})
		this.props.fetchMenstruations({
			from,
			to,
		})
		this.props.getBowels({
			from,
			to,
		})

		this.props.getExercises({
			from,
			to,
		})

		this.props.getMeals({
			from,
			to,
		})

		this.props.getMedications({
			from,
			to,
		})

		this.props.getProgramSurveys({
			from,
			to,
		})

		this.props.getMoods({
			from,
			to,
		})

		this.props.getOthers({
			from,
			to,
		})

		this.props.getSleeps({
			from,
			to,
		})

		this.props.getSymptoms({
			from,
			to,
		})
	}

	isNextDisabled = () => {
		const currentDate = new Date(moment(this.state.currentDay))
		const today = new Date()

		return (
			currentDate.getFullYear() === today.getFullYear() &&
			currentDate.getMonth() === today.getMonth() &&
			currentDate.getDate() === today.getDate()
		)
	}

	getEntityItem = (type, id) => {
		const activeSyncedItem =
			this.props[type] &&
			this.props[type].data &&
			this.props[type].data.find((item) => {
				const unique = validate(id)
				if (unique) {
					return item._app.uuid === id
				}
				if (item.id) {
					return item.id === id
				}
			})
		return activeSyncedItem
	}

	groupEventsByTimeRange = (events) => {
		const initialRangeShape = {
			MORNING: [],
			AFTERNOON: [],
			NIGHT: [],
		}

		const TIME_RANGES = this.getCurrentRanges()

		if (events.length > 0) {
			return events.reduce((accum, event) => {
				const isMorning = moment(event.occurred_at).isBetween(
					TIME_RANGES.MORNING.START,
					TIME_RANGES.MORNING.STOP,
					'[]',
					true
				)

				const isAfternoon = moment(event.occurred_at).isBetween(
					TIME_RANGES.AFTERNOON.START,
					TIME_RANGES.AFTERNOON.STOP,
					'[]',
					true
				)

				const isNight = moment(event.occurred_at).isBetween(
					TIME_RANGES.NIGHT.START,
					TIME_RANGES.NIGHT.STOP,
					'[]',
					true
				)

				if (isMorning) {
					return { ...accum, MORNING: [...accum.MORNING, event] }
				} else if (isAfternoon) {
					return { ...accum, AFTERNOON: [...accum.AFTERNOON, event] }
				} else if (isNight) {
					return { ...accum, NIGHT: [...accum.NIGHT, event] }
				}
				return accum
			}, initialRangeShape)
		} else {
			return initialRangeShape
		}
	}

	isFururePhase = (phaseStare) => {
		const phaseTime = new Date(phaseStare)
		const nowTime = new Date()
		return phaseTime > nowTime
	}

	groupedEventsFilter = (eventGroups) => {
		Object.keys(eventGroups).forEach((datePhase) => {
			const phaseHasMeal =
				!this.rangeExclusivelyFasts(eventGroups[datePhase]) && this.rangeHasMeal(eventGroups[datePhase])
			eventGroups[datePhase] = eventGroups[datePhase].reduce((acc, phaseEvent) => {
				phaseEvent._app.renderType = phaseEvent._app.type
				const isFast = this.isFast(phaseEvent)
				if (isFast) {
					if (this.rangeHasFast(acc)) {
						// don't show multiple fasts
						phaseEvent._app.renderType = 'skip'
						return acc
					}
					if (phaseHasMeal) {
						// remove fasts if a meal is in the phase
						phaseEvent._app.renderType = 'skip'
						return acc
					}
					phaseEvent._app.renderType = 'fast'
				}

				return [...acc, phaseEvent]
			}, [])
		})
		return eventGroups
	}

	rangeHasMeal = (events) => {
		return events.length > 0 && events.some((event) => event._app.type === 'meal')
	}

	rangeHasFast = (events) => {
		return events.length > 0 && events.some((event) => this.isFast(event))
	}

	rangeExclusivelyFasts = (events) => {
		return events.length > 0 && events.every((event) => this.isFast(event))
	}

	rangeIsNightWithNoMeds = (range, events) => {
		const hasMeds = events.some((event) => event._app.type === 'medication')
		const result = range === 'night' && !hasMeds
		return result
	}

	isFast = (event) => {
		const fullMeal = this.props.meal.data.find((meal) => {
			if (meal.id) {
				return meal.id === event.id
			} else if (meal._app.uuid) {
				return meal._app.uuid === event._app.uuid
			}
		})
		if (fullMeal) {
			return (
				fullMeal._app.type === 'meal' &&
				!fullMeal.name &&
				!fullMeal.notes &&
				(!fullMeal.items || !fullMeal.items.length) &&
				(!fullMeal.media || !fullMeal.media.length)
			)
		}
		return false
	}

	isNoMed = (event) => {
		const fullMed = [...this.props.medication._data, ...this.props.medication.data].find((med) => {
			if (med.id) {
				return med.id === event.id
			} else if (med._app.uuid) {
				return med._app.uuid === event._app.uuid
			}
		})
		if (fullMed) {
			return (
				fullMed._app.type === 'medication' && !fullMed.notes && (!fullMed.types || fullMed.types.length === 0)
			)
		}
		return false
	}

	getCurrentRanges = () => {
		const momentDate = moment(this.state.currentDay)
		return {
			MORNING: {
				ORDER: 1,
				START: getTimeAtCurrentDay(momentDate, 0),
				STOP: getTimeAtCurrentDay(momentDate, 11, 59),
				isFuture: this.isFururePhase(getTimeAtCurrentDay(momentDate, 0)),
			},
			AFTERNOON: {
				ORDER: 2,
				START: getTimeAtCurrentDay(momentDate, 12),
				STOP: getTimeAtCurrentDay(momentDate, 17, 59),
				isFuture: this.isFururePhase(getTimeAtCurrentDay(momentDate, 12)),
			},
			NIGHT: {
				ORDER: 3,
				START: getTimeAtCurrentDay(momentDate, 18),
				STOP: getTimeAtCurrentDay(momentDate, 23, 59),
				isFuture: this.isFururePhase(getTimeAtCurrentDay(momentDate, 18)),
			},
		}
	}

	checkAndReturnDehydratedRangeProposal = (events) => {
		const dehydratedEvents = events.filter((event) => {
			return !event._app.title
		})
		if (dehydratedEvents.length) {
			const fromDate = new Date(moment(this.state.historyDateAnchor))
			const toDate = new Date(moment(this.state.currentDate))
			const from = new Date(fromDate.setDate(fromDate.getDate() - 1)).toISOString()
			const to = new Date(toDate.setDate(toDate.getDate() + 1)).toISOString()
			// const from = fromDate.toISOString()
			// const to = toDate.toISOString()
			const types = new Set()
			dehydratedEvents.forEach((event) => {
				types.add(event._app.type)
			})
			types.add('symptomScore')
			types.add('programSurvey')
			types.add('user_program_survey')
			types.forEach((dehydratedType) => {
				if (!activeFetchTypes.has(dehydratedType)) {
					activeFetchTypes.add(dehydratedType)
					switch (dehydratedType) {
						case 'sleep':
							this.props
								.getSleeps({
									from,
									to,
								})
								.then(() => {
									activeFetchTypes.delete(dehydratedType)
								})
							break
						case 'bowel':
							this.props
								.getBowels({
									from,
									to,
								})
								.then(() => {
									activeFetchTypes.delete(dehydratedType)
								})
							break
						case 'mood':
							this.props
								.getMoods({
									from,
									to,
								})
								.then(() => {
									activeFetchTypes.delete(dehydratedType)
								})
							break
						case 'symptom':
							this.props
								.getSymptoms({
									from,
									to,
								})
								.then(() => {
									activeFetchTypes.delete(dehydratedType)
								})
							break
						case 'meal':
							this.props
								.getMeals({
									from,
									to,
								})
								.then(() => {
									activeFetchTypes.delete(dehydratedType)
								})
							break
						case 'other':
							this.props
								.getOthers({
									from,
									to,
								})
								.then(() => {
									activeFetchTypes.delete(dehydratedType)
								})
							break
						case 'medication':
							this.props
								.getMedications({
									from,
									to,
								})
								.then(() => {
									activeFetchTypes.delete(dehydratedType)
								})
							break
						case 'programSurvey':
						case 'program_survey':
						case 'user_program_survey':
							this.props
								.getProgramSurveys({
									from,
									to,
								})
								.then(() => {
									activeFetchTypes.delete(dehydratedType)
								})
							break
						case 'exercise':
							this.props
								.getExercises({
									from,
									to,
								})
								.then(() => {
									activeFetchTypes.delete(dehydratedType)
								})
							break
						default:
							break
					}
				}
			})
		}
	}

	splitRangesByPastFuture = (currentRanges, filterGroupedByRange) => {
		Object.keys(filterGroupedByRange).forEach((dayPhase) => {
			filterGroupedByRange[dayPhase] = filterGroupedByRange[dayPhase].map((event) => {
				event.isFuture = currentRanges[dayPhase].isFuture
				return event
			})
		})
		return filterGroupedByRange
	}

	groupedByDate = (events) => {
		return groupBy(events, (item) => {
			return moment(item.occurred_at).format('YYYY-MM-DD')
		})
	}

	onEventInteract = async (event, type, id) => {
		this.setState({ contextMenuAnchor: event.currentTarget })
	}

	handleCloseContextMenu = () => {
		this.setState({ contextMenuAnchor: null })
	}

	// be extra defensive and
	dedupeEvents = (events) => {
		const groups = {}
		events.forEach((event) => {
			if (!event._app) {
				event._app = {}
			}
			if (!groups[event._app.type]) {
				groups[event._app.type] = []
			}
			groups[event._app.type].push(event)
		})
		Object.keys(groups).forEach((type) => {
			if (groups[type]) {
				groups[type] = groups[type].reverse().reduce((acc, event) => {
					if (event.id) {
						if (!acc.map((grpItem) => grpItem.id).includes(event.id)) {
							return [...acc, event]
						}
					} else if (event._app && event._app.uuid) {
						if (!acc.map((grpItem) => grpItem._app.uuid).includes(event._app.uuid)) {
							return [...acc, event]
						}
					}
					return acc
				}, [])
			}
		})
		const typeDeduped = Object.keys(groups).reduce((acc, type) => {
			return [...acc, ...groups[type]]
		}, [])

		return Object.values(
			typeDeduped.reduce((unique, o) => {
				if (!o._app.uuid) {
					o._app.uuid = uuidv4()
				}
				if (!unique[o._app.uuid]) {
					unique[o._app.uuid] = o
				}
				return unique
			}, {})
		)
	}

	loadExternalMedias = (events) => {
		if (this.props.device.settings?.diary?.showImages) {
			if (events) {
				for (const event of events) {
					if (event.uuid && (!event.media || event.media?.length === 0)) {
						if (!activeLoadMedias.has(event.uuid)) {
							activeLoadMedias.add(event.uuid)
							this.props.readMedia(event, `${event._app.type}s`)
						}
					}
				}
			}
		}
	}

	refetchMediaImage = (event) => {
		if (this.props.device.settings?.diary?.showImages) {
			this.props.readMedia(event, `${event._app.type}s`)
		}
	}

	enrichEventEntityMedia = (events) => {
		for (const event of events) {
			const entity = this.getEntityItem(event._app.type, event.id || event._app.uuid)
			event.media = entity?.media
			event._labels = entity?._labels

			if (entity?.ProgramSurvey) {
				event.ProgramSurvey = entity.ProgramSurvey
			}
		}
		return events
	}

	entityBecomesVisible = (day, isVisible, entry) => {
		// 	if (isVisible) {
		// 		this.setState(
		//    {
		//      entitiesVisible: {
		//        ...this.state.entitiesVisible,
		//        [day]: isVisible,
		//      },
		//    },
		//    () => {
		//      if (this.state.entitiesVisible[day]) {
		//        this.loadExternalMedias(this.state.groupedByDate[day])
		//      }
		//    }
		//  )
		// 	}
		if (isVisible) {
			// this.loadExternalMedias(this.state.groupedByDate[day])
		}
	}

	fuse(data) {
		// const opts = {
		// 	threshold: 0,
		// 	shouldSort: true,
		// 	includeScore: true,
		// 	minMatchCharLength: 4,
		// 	keys: ['_app.title', '_app.subTitle'],
		// }
		// const fuse = new Fuse(data, opts)
		// const res = this.state.searchVal
		// 	? fuse.search(this.state.searchVal).reduce((acc, res) => {
		// 			acc.push(res.item)
		// 			return acc
		// 	  }, [])
		// 	: data
		// return res
		return this.state.searchVal
			? data.reduce((acc, item) => {
					if (
						item?._app?.title?.toLowerCase()?.includes(this.state.searchVal?.toLowerCase()) ||
						item?._app?.subTitle?.toLowerCase()?.includes(this.state.searchVal?.toLowerCase())
					) {
						acc.push(item)
					}
					return acc
			  }, [])
			: data
	}

	// preloadTopDays = _debounce(
	// 	() => {
	// 		const days = getRange(
	// 			moment(this.state.currentDate).subtract(5, 'days'),
	// 			moment(this.state.currentDate),
	// 			'days'
	// 		)
	// 		console.log('preloading', days)
	// 		days.forEach((dayLoad) => {
	// 			this.loadExternalMedias(this.state.groupedByDate[dayLoad.format('YYYY-MM-DD')])
	// 		})
	// 	},
	// 	4000,
	// 	{
	// 		leading: true,
	// 		trailing: false,
	// 	}
	// )

	render() {
		const { isLoading } = this.props
		const days = EnumerateDaysBetweenDates(moment(this.state.historyDateAnchor), moment(this.state.currentDate))
		const enrichedDayGroups = days.reduce((acc, day) => {
			const todaysEvents =
				this.state.groupedByDate && this.state.groupedByDate[day] ? this.state.groupedByDate[day] : []
			const dedupedDaysEvents = this.dedupeEvents(todaysEvents)
			const filterVisibleEntityTypes = dedupedDaysEvents.filter((event) => {
				return this.props.device.settings?.diary?.interestedDiaryEntities.includes(event._app.type)
			})
			const enrichedEvents = this.enrichEventEntityMedia(filterVisibleEntityTypes).sort((a, b) => {
				if (a.occurred_at && b.occurred_at) {
					return new Date(a.occurred_at) - new Date(b.occurred_at)
				}
				return a.id - b.id
			})

			acc[day] = this.fuse(enrichedEvents)
			return acc
		}, {})

		let hasUnlabeled = false
		const totalEvents = Object.keys(enrichedDayGroups).reduce((acc, day) => {
			const events = enrichedDayGroups[day]
			const unlabledDay = events.reduce((acc, entity) => {
				if (entity.uuid && entity._app?.unlabled) {
					acc = true
				}
				return acc
			}, false)
			if (unlabledDay) {
				hasUnlabeled = unlabledDay
			}
			// if (this.state.entitiesVisible[day]) {
			// 	this.loadExternalMedias(events)
			// }
			acc = [...acc, ...events]
			return acc
		}, [])
		// this.preloadTopDays()
		this.checkAndReturnDehydratedRangeProposal(totalEvents)

		return (
			<Root className={classes.container}>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<SymptomScoresChart
							scores={this.props.symptomScore.daysScores}
							range={{ start: this.state.historyDateAnchor, end: this.state.currentDay }}
							onRangeSelect={(dateA, dateB) => {
								console.log('onRangeSelect')
							}}
							onRefresh={(dateA, dateB) => {
								this.props.fetchSymptomScores({
									from: dateA,
									to: dateB,
								})
								this.props.fetchMenstruations({
									from: dateA,
									to: dateB,
								})
							}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={4} className={classes.separate}>
					<Grid item xs={12} className={classes.contentGridMain}>
						<DiaryEntityChiplist />
					</Grid>
				</Grid>
				<Grid container spacing={4}>
					<Grid item lg={8} md={7} xl={8} xs={12} className={classes.contentGridMain}>
						{/* {hasUnlabeled ? (
							<Grid item xs={12} className={classes.alertMargin}>
								<Alert variant="outlined" severity="error">
									<AlertTitle>
										Selected day {Object.keys(enrichedDayGroups).length > 1 ? 'range' : ''} has
										unlabled entries
									</AlertTitle>
									Unlabled entries are present in this journal section. Go into the mobile app and
									lable your data!
								</Alert>
							</Grid>
						) : (
							<></>
						)} */}
						<Paper className={classes.paper}>
							<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
								<Toolbar>
									<Grid container spacing={2} alignItems="center">
										<Grid item>
											<SearchIcon className={classes.block} color="inherit" />
										</Grid>
										<Grid item xs>
											<TextField
												fullWidth
												value={this.state.searchVal}
												onChange={(event) => {
													this.setState({ searchVal: event.target.value })
												}}
												placeholder={`Search by event this ${
													moment(this.state.historyDateAnchor).format('YYYY-MM-DD') ===
													this.state.currentDay
														? 'day'
														: 'day range'
												}`}
												InputProps={{
													disableunderline: 'true',
													className: classes.searchInput,
													inputProps: {
														className: classes.compactSearch,
													},
												}}
											/>
										</Grid>

										<Grid item>
											<Tooltip arrow title="Reload" aria-label="reload">
												<IconButton color="inherit" onClick={this.onRefresh} size="large">
													<RefreshIcon className={classes.block} color="inherit" />
												</IconButton>
											</Tooltip>
											<IconButton
												color="inherit"
												disabled={!this.props?.sub}
												onClick={() => {
													navigate(`/chat/${this.props.sub}`)
												}}
												size="large"
											>
												<ChatBubbleIcon className={classes.block} color="inherit" />
											</IconButton>
										</Grid>
									</Grid>
								</Toolbar>
							</AppBar>

							{Object.keys(enrichedDayGroups)
								.reverse()
								.map((day, i) => {
									const dayScore = this.props.symptomScore.daysScores[day]
										? this.props.symptomScore.daysScores[day][0].score
										: null

									let activePeriod = null
									const today = moment(this.props.currentDate).startOf('day').format('YYYY-MM-DD')
									const relevantDays = Object.keys(this.props.menstruation.daysMenstruations)
										.sort()
										.reverse()
										.filter((day) => moment(day).isSameOrBefore(moment(today)))
									if (relevantDays.length) {
										const latestMentruationDate = relevantDays[0]
										const latestMentruation =
											this.props.menstruation.daysMenstruations[latestMentruationDate][0]
										const startDate = moment(latestMentruation.range_start)
										const endDate = moment(latestMentruation.range_end)

										if (
											moment(day).isBetween(
												moment(startDate).startOf('day'),
												moment(endDate).startOf('day'),
												null,
												'[]'
											)
										) {
											activePeriod = latestMentruation
										}
									}

									const enrichedEvents = enrichedDayGroups[day]
									if (this.state.searchVal && enrichedEvents.length === 0) {
										return <></>
									}
									return (
										<InView
											key={`${day}-${i}`}
											onChange={(isVisible, entry) =>
												this.entityBecomesVisible(day, isVisible, entry)
											}
										>
											{({ inView, ref, entry }) => {
												return (
													<div ref={ref}>
														<Grid
															container
															justifyContent="space-between"
															alignItems="center"
															className={classes.diaryDayHeader}
														>
															<Grid item xs>
																<Typography
																	color="textSecondary"
																	align="left"
																	variant="h5"
																>
																	{moment(day).format('MMMM D, YYYY')}
																</Typography>
															</Grid>
															{activePeriod ? (
																<Grid item alignItems="center">
																	{<RenderMenstruatingIcon classes={classes} />}
																</Grid>
															) : (
																<></>
															)}
															{Number.isInteger(dayScore) ? (
																<Tooltip
																	title={`Felt ${mapScoreToText(dayScore)}`}
																	arrow
																>
																	<img
																		src={mapScoreToEmoticons(dayScore)}
																		style={{
																			justifyContent: 'center',
																			alignItems: 'center',
																			marginRight: 10,
																			width: 30,
																			height: 30,
																		}}
																	/>
																</Tooltip>
															) : (
																<></>
															)}
														</Grid>

														<div className={classes.loadingContentWrapper}>
															{enrichedEvents.length === 0 && isLoading ? (
																<Grid container justifyContent="center">
																	<CircularProgress disableShrink={true} />
																</Grid>
															) : (
																<></>
															)}
															{enrichedEvents.length === 0 && !isLoading ? (
																<Typography color="textSecondary" align="center">
																	No entries logged for this day
																</Typography>
															) : (
																<></>
															)}
															{enrichedEvents.map((event, i) => {
																return (
																	<EventCard
																		index={i}
																		shouldShowImages={
																			inView && // this is kinda buggy unfortunately
																			this.props.device.settings?.diary
																				?.showImages
																		}
																		totalEvents={enrichedEvents.length}
																		key={event.id}
																		event={event}
																		onError={() => this.refetchMediaImage(event)}
																		onClick={this.onEventInteract}
																		onOpenCarousel={
																			this.props.handleClickOpenCarousel
																		}
																		onClickAnnotate={(event) => {
																			if (event?.uuid) {
																				this.props.loadAnnotationsFor(
																					event?.uuid
																				)
																				this.props.loadTargetBowel(event)
																				navigate('/annotations')
																			}
																		}}
																	/>
																)
															})}
														</div>
													</div>
												)
											}}
										</InView>
									)
								})}
						</Paper>
					</Grid>
					<Grid item lg={4} md={5} xl={4} xs={12} className={classes.contentGridSecondary}>
						<StickyDiaryCalendar
							range={[this.state.historyDateAnchor, this.state.currentDay]}
							onChangedDate={(dateA, dateB) => {
								this.onSetDateRange(dateA, dateB)
							}}
						/>
					</Grid>
				</Grid>
			</Root>
		)
	}
}

DayContent.propTypes = {
	handleClickOpenCarousel: PropTypes.func.isRequired,
	annotations: PropTypes.object,
	summary: PropTypes.shape({
		events: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				occurred_at: PropTypes.oneOfType([
					PropTypes.instanceOf(Date),
					PropTypes.instanceOf(moment),
					PropTypes.string,
				]),
				_app: PropTypes.shape({
					uuid: PropTypes.string,
				}),
			})
		),
		attentionDate: PropTypes.oneOfType([
			PropTypes.instanceOf(Date),
			PropTypes.instanceOf(moment),
			PropTypes.string,
		]),
		daysEvents: PropTypes.object.isRequired,
		loading: PropTypes.bool.isRequired,
	}).isRequired,
	other: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	symptom: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	mood: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	bowel: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	sleep: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	exercise: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	meal: PropTypes.shape({
		data: PropTypes.array.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	medication: PropTypes.shape({
		data: PropTypes.array.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	menstruation: PropTypes.shape({
		daysMenstruations: PropTypes.object.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	device: PropTypes.shape({
		settings: PropTypes.shape({
			diary: PropTypes.shape({
				interestedDiaryEntities: PropTypes.array,
				showImages: PropTypes.bool,
			}).isRequired,
		}).isRequired,
	}).isRequired,
	setAttentionDate: PropTypes.func.isRequired,
	getSleeps: PropTypes.func.isRequired,
	getBowels: PropTypes.func.isRequired,
	getMoods: PropTypes.func.isRequired,
	getSymptoms: PropTypes.func.isRequired,
	getMeals: PropTypes.func.isRequired,
	getOthers: PropTypes.func.isRequired,
	getMedications: PropTypes.func.isRequired,
	getExercises: PropTypes.func.isRequired,
	getProgramSurveys: PropTypes.func.isRequired,
	fetchSummary: PropTypes.func.isRequired,
	fetchSymptomScores: PropTypes.func.isRequired,
	fetchMenstruations: PropTypes.func.isRequired,
	readMedia: PropTypes.func.isRequired,
	loadTargetBowel: PropTypes.func.isRequired,
	loadAnnotationsFor: PropTypes.func.isRequired,
	annotations: PropTypes.object,
}

const mapStateToProps = (state) => {
	return state
}

export default compose(connect(mapStateToProps, actions), withTranslation())(DayContent)
