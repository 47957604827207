import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Grid from '@mui/material/Grid'

import { withTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
	faDumbbell,
	faPills,
	faQuestionCircle,
	faUtensils,
	faNotesMedical,
	faSmile,
	faPoo,
	faBed,
	faClipboardList,
} from '@fortawesome/free-solid-svg-icons'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import Divider from '@mui/material/Divider'

import { ENTITY_THEME, DIARY_ENTITY_TYPES } from 'constants'

const PREFIX = 'EntityChipList'

const classes = {
	root: `${PREFIX}-root`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}))

const getIcon = (type, index, list, handleClick, handleDelete) => {
	const isEnabled = list.includes(type)
	const deleteIcon = isEnabled ? (
		<VisibilityIcon style={{ color: '#303030' }} />
	) : (
		<VisibilityOffIcon style={{ color: '#303030' }} />
	)

	const iconStyles = {
		padding: 2,
		color: '#303030',
		fontSize: 18,
	}

	const chipStyles = {
		color: '#303030',
		opacity: isEnabled ? 1 : 0.2,
	}

	const onClick = () => (isEnabled ? handleDelete() : handleClick())
	const onDelete = () => (isEnabled ? handleDelete() : handleClick())
	switch (type) {
		case 'sleep':
			return (
				<Chip
					key={type}
					size="small"
					icon={<FontAwesomeIcon style={iconStyles} icon={faBed} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.SLEEP,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
		case 'bowel':
			return (
				<Chip
					key={type}
					size="small"
					icon={<FontAwesomeIcon style={iconStyles} icon={faPoo} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.BOWEL,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
		case 'mood':
			return (
				<Chip
					key={type}
					size="small"
					icon={<FontAwesomeIcon style={iconStyles} icon={faSmile} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.MOOD,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
		case 'symptom':
			return (
				<Chip
					key={type}
					size="small"
					icon={<FontAwesomeIcon style={iconStyles} icon={faNotesMedical} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.SYMPTOM,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
		case 'meal':
			return (
				<Chip
					key={type}
					size="small"
					icon={<FontAwesomeIcon style={iconStyles} icon={faUtensils} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.MEAL,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
		case 'other':
			return (
				<Chip
					key={type}
					size="small"
					icon={<FontAwesomeIcon style={iconStyles} icon={faQuestionCircle} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.OTHER,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
		case 'medication':
			return (
				<Chip
					key={type}
					size="small"
					icon={<FontAwesomeIcon style={iconStyles} icon={faPills} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.MEDICATION,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
		case 'exercise':
			return (
				<Chip
					key={type}
					size="small"
					icon={<FontAwesomeIcon style={iconStyles} icon={faDumbbell} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.EXERCISE,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
		case 'programSurvey':
		case 'program_survey':
		case 'user_program_survey':
			return (
				<Chip
					key={type}
					size="small"
					icon={<FontAwesomeIcon style={iconStyles} icon={faClipboardList} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.PROGRAM_SURVEY,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
		default:
			return (
				<Chip
					key={type}
					size="small"
					icon={<HelpOutlineIcon style={iconStyles} />}
					style={{
						...chipStyles,
						backgroundColor: ENTITY_THEME.SLEEP,
					}}
					label={type}
					deleteIcon={deleteIcon}
					onClick={onClick}
					onDelete={onDelete}
				/>
			)
	}
}

const onClick = (currentSet, type, cb) => {
	const set = new Set(currentSet)
	set.add(type)
	cb(Array.from(set))
}

const onDelete = (currentSet, type, cb) => {
	const set = new Set(currentSet)
	set.delete(type)
	cb(Array.from(set))
}

function DiaryEntityChiplist(props) {
	const {} = props

	return (
		<Root className={classes.root}>
			{DIARY_ENTITY_TYPES.map((type, index) => {
				return getIcon(
					type,
					index,
					props.device.settings.diary.interestedDiaryEntities,
					() =>
						onClick(
							props.device.settings.diary.interestedDiaryEntities,
							type,
							props.updateDeviceSettingDiaryInterestEntities
						),
					() =>
						onDelete(
							props.device.settings.diary.interestedDiaryEntities,
							type,
							props.updateDeviceSettingDiaryInterestEntities
						)
				)
			})}

			<Divider orientation="vertical" flexItem />

			<Chip
				size="small"
				style={{}}
				deleteIcon={<VisibilityOffIcon style={{ color: '#303030' }} />}
				label={'Hide All'}
				onClick={() => props.updateDeviceSettingDiaryInterestEntities([])}
				onDelete={() => {}}
			/>
			<Chip
				size="small"
				style={{}}
				deleteIcon={<VisibilityIcon style={{ color: '#303030' }} />}
				label={'Show All'}
				onClick={() => props.updateDeviceSettingDiaryInterestEntities(DIARY_ENTITY_TYPES)}
				onDelete={() => {}}
			/>

			<Divider orientation="vertical" flexItem />

			<Chip
				size="small"
				style={{
					opacity: props.device.settings.diary.showImages ? 1 : 0.2,
				}}
				icon={<BurstModeIcon style={{ color: '#303030' }} />}
				deleteIcon={
					props.device.settings.diary.showImages ? (
						<VisibilityIcon style={{ color: '#303030' }} />
					) : (
						<VisibilityOffIcon style={{ color: '#303030' }} />
					)
				}
				label={'Images'}
				onClick={() =>
					props.updateDeviceSettingDiaryImageRenderPreference(!props.device.settings.diary.showImages)
				}
				onDelete={() => {}}
			/>
		</Root>
	)
}

DiaryEntityChiplist.propTypes = {
	device: PropTypes.shape({
		settings: PropTypes.shape({
			diary: PropTypes.shape({
				interestedDiaryEntities: PropTypes.array,
				showImages: PropTypes.bool,
			}),
		}),
	}),
	updateDeviceSettingDiaryInterestEntities: PropTypes.func.isRequired,
	updateDeviceSettingDiaryImageRenderPreference: PropTypes.func.isRequired,
}

const mapStateToProps = ({ device }) => {
	return {
		device,
	}
}
export default compose(connect(mapStateToProps, actions), withTranslation())(DiaryEntityChiplist)
