import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import TabBar from 'molecules/TabBar'
import DayContent from './DayContent'
import WeekContent from './WeekContent'
import MonthContent from './MonthContent'
import { withTranslation } from 'react-i18next'
import { LinearProgress, ImageCarouselDialog } from '@components'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'

import moment from 'moment'

const PREFIX = 'index'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	addUser: `${PREFIX}-addUser`,
	container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.paper}`]: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.searchInput}`]: {
		fontSize: theme.typography.fontSize,
	},

	[`& .${classes.block}`]: {
		display: 'block',
	},

	[`& .${classes.addUser}`]: {
		marginRight: theme.spacing(1),
	},

	[`& .${classes.container}`]: {
		padding: '48px 36px 0',
	},
}))

const tabNames = ['Day']

function DiaryContent(props) {
	const { summary } = props
	const [query, setQuery] = useQueryParams({
		day: StringParam,
		tab: withDefault(StringParam, tabNames[0]),
	})

	const [carouselImages, setCarouselImages] = React.useState([])
	const [carouselUuid, setCarouselUuid] = React.useState([])
	const [carouselImageIndex, setCarouselImageIndex] = React.useState(0)
	const [openImageCarousel, setImageCarousel] = React.useState(false)

	const handleClickOpenCarousel = (images, index, uuid, stoolConfidence) => {
		if (stoolConfidence?.perImageConfidence && stoolConfidence?.perImageConfidence.length) {
			images = images.map((image) => ({
				...image,
				...stoolConfidence.perImageConfidence.find((confidence) => confidence.img_id === image.name),
			}))
		}
		setCarouselImages(images)
		setCarouselImageIndex(index)
		setCarouselUuid(uuid)
		setImageCarousel(true)
	}
	const handleCloseCarousel = () => {
		setImageCarousel(false)
	}
	const onTabChange = (newValue, tabName) => {
		setQuery({ tab: tabName })
	}

	const isLoading =
		props.summary.loading ||
		props.bowel.loading.read ||
		props.exercise.loading.read ||
		props.meal.loading.read ||
		props.medication.loading.read ||
		props.mood.loading.read ||
		props.other.loading.read ||
		props.programSurvey.loading.read ||
		props.sleep.loading.read ||
		props.symptom.loading.read

	if (query.day) {
		setQuery({ day: undefined })
		summary.attentionDate = query.day
	}

	return (
		<Root>
			<TabBar tabNames={tabNames} tab={query.tab} onTabChange={onTabChange} />
			<LinearProgress visible={isLoading} color="primary" />
			{
				{
					Day: <DayContent isLoading={isLoading} handleClickOpenCarousel={handleClickOpenCarousel} sub={props?.patient?.current?.key} />,
					// Week: <WeekContent isLoading={isLoading} />,
					// Month: <MonthContent isLoading={isLoading} />,
				}[query.tab]
			}
			<ImageCarouselDialog
				key={`${carouselUuid}-${new Date().getTime()}`}
				open={openImageCarousel}
				entityImages={carouselImages}
				startIndex={carouselImageIndex}
				handleClickOpen={handleClickOpenCarousel}
				handleClose={handleCloseCarousel}
			/>
		</Root>
	)
}

DiaryContent.propTypes = {
	location: PropTypes.object.isRequired,
	summary: PropTypes.shape({
		events: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				occurred_at: PropTypes.oneOfType([
					PropTypes.instanceOf(Date),
					PropTypes.instanceOf(moment),
					PropTypes.string,
				]),
				_app: PropTypes.shape({
					uuid: PropTypes.string,
				}),
			})
		),
		attentionDate: PropTypes.oneOfType([
			PropTypes.instanceOf(Date),
			PropTypes.instanceOf(moment),
			PropTypes.string,
		]),
		daysEvents: PropTypes.object.isRequired,
		loading: PropTypes.bool.isRequired,
	}).isRequired,
	other: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	symptom: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	mood: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	bowel: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	sleep: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	exercise: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	meal: PropTypes.shape({
		data: PropTypes.array.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	medication: PropTypes.shape({
		data: PropTypes.array.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	programSurvey: PropTypes.shape({
		data: PropTypes.array.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	fetchSummary: PropTypes.func.isRequired,
	fetchSymptomScores: PropTypes.func.isRequired,
	fetchMenstruations: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
	return state
}

export default compose(connect(mapStateToProps, actions), withTranslation())(DiaryContent)
