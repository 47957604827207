import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
const PREFIX = 'WeekContent'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	contentWrapper: `${PREFIX}-contentWrapper`,
	container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.paper}`]: {
		margin: 'auto',
		overflow: 'hidden',
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.searchInput}`]: {
		fontSize: theme.typography.fontSize,
	},

	[`& .${classes.block}`]: {
		display: 'block',
	},

	[`& .${classes.contentWrapper}`]: {
		margin: '20px 16px',
	},

	[`&.${classes.container}`]: {
		padding: '48px 36px 0',
	},
}))

function WeekContent({}) {
	return (
		<Root className={classes.container}>
			<Paper className={classes.paper}>
				<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
					<Toolbar>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<SearchIcon className={classes.block} color="inherit" />
							</Grid>
							<Grid item xs>
								<TextField
									fullWidth
									placeholder="Search by event this week"
									InputProps={{
										disableunderline: 'true',
										className: classes.searchInput,
									}}
								/>
							</Grid>
							<Grid item>
								<Tooltip arrow title="Reload" aria-label="reload">
									<IconButton color="inherit" size="large">
										<RefreshIcon className={classes.block} color="inherit" />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				<div className={classes.contentWrapper}>
					<Typography color="textSecondary" align="center">
						Not yet implemented. Stay tuned
					</Typography>
				</div>
			</Paper>
		</Root>
	)
}

WeekContent.propTypes = {}

export default WeekContent
